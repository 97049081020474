import React from "react";
import { Card, JText } from "../jlib";
import { View, ActivityIndicator } from "react-native";
import { SwitchWithIcon } from "../common";
import fnStyles from "./ParamsItemStyle";
import useTheme from "../../theme/ThemeProvider";
import { useUser } from "../../hooks";
import { useTranslation } from "react-i18next";

type Props = {
  id: number;
  name: string;
  description: string;
  onPress: () => void;
  value: boolean;
};

export const ParamsItem: React.FC<Props> = ({
  id,
  name,
  description,
  onPress,
  value,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const { isParamItemChanging } = useUser();

  const onToggle = () => {
    onPress();
  };

  return (
    <Card style={styles.container} disabled noShadow>
      <View style={styles.main}>
        <JText label={name} isBold nativeID={`toggle-label-${name}`} />
        <JText
          label={description}
          isItalic
          sizeName={"mediumLarge"}
          fontWeight={"300"}
        />
      </View>
      {isParamItemChanging === `${id}` ? (
        <View style={{ padding: theme.sizings.small }}>
          <ActivityIndicator color={theme.colors.greyDark} size="small" />
        </View>
      ) : (
        <SwitchWithIcon
          value={value}
          onToggle={onToggle}
          iconType={"MaterialCommunity"}
          iconName={"bell"}
          accessibilityLabel={value ? t("labels.disable") : t("labels.enable")}
          accessibilityLabelledBy={`toggle-label-${name}`}
        />
      )}
    </Card>
  );
};
