import React from "react";
import { ImageBackground, StyleProp, View, ViewStyle } from "react-native";

import useTheme from "theme/ThemeProvider";
import fnStyles from "./BackgroundLayoutStyle";

type Props = {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode[] | React.ReactNode;
  withImage?: boolean;
};

export const BackgroundLayout: React.FC<Props> = ({
  style,
  withImage = true,
  children,
}) => {
  const { theme } = useTheme();

  const styles = fnStyles();

  return (
    <View style={[styles.container, withImage ? null : style]}>
      {withImage ? (
        <ImageBackground
          source={theme.images.background}
          style={[styles.background, style]}
        >
          {children}
        </ImageBackground>
      ) : (
        <>{children}</>
      )}
    </View>
  );
};
