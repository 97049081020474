export default {
  logo: {
    width: 150,
  },
  sites: {
    wording: {
      Sg_main: "chantier",
      Pl_main: "chantiers",
    },
    display: {
      organization: false,
      searchBar: true,
      typesSeparator: false,
    },
    features: {
      locationMandatory: false,
      suggestionForm: false,
    },
    map: {
      defaultCenter: "default",
      defaultCenterPoint: {
        lat: 43.6043,
        lng: 1.4437,
      },
      defaultZoomLevel: 13,
      defaultMobileZoomLevel: 1,
    },
  },
  profile: {
    email: {
      share: true,
    },
    phone: {
      share: true,
      sms: false,
    },
    features: {
      loginMandatory: false,
      hasUserType: true,
      enableAboveFifteen: true,
    },
  },
  threads: {
    features: {
      enableThreadWithoutSite: true,
      enableThreadWithLocation: true,
    },
  },
};
