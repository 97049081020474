import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { ReactNode } from "react";
import { View } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { Card } from "../../jlib";
import fnStyles from "./SimpleListItemStyle";

type SimpleListItemProps<T> = {
  item: T;
  onPress: (item: T) => void;
  children: ReactNode;
  isSelected?: boolean;
};

export const SimpleListItem = <T extends {}>({
  item,
  onPress,
  children,
  isSelected = false,
}: SimpleListItemProps<T>) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme, isSelected);

  return (
    <BoxedLayout>
      <View style={styles.container}>
        <Card style={styles.card} onPress={() => onPress(item)}>
          <View style={styles.content}>{children}</View>
        </Card>
      </View>
    </BoxedLayout>
  );
};
