import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";;
import hexToRgba from "hex-to-rgba";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      marginVertical: theme.normalize(10),
    },
    main: {
      flex: 1,
    },
    clickable: {
      flex: 1,
      flexDirection: "row",
      alignItems: "flex-start",
      paddingRight: theme.sizings.mediumLarge,
    },
    icon: {
      backgroundColor: hexToRgba(theme.colors.info, 0.2),
      padding: theme.normalize(8),
      borderRadius: 100,
      borderColor: theme.colors.info,
      borderWidth: 1,
      marginRight: theme.normalize(10),
    },
    iconSpacing: {
      marginLeft: theme.normalize(2),
    },
    textSpacing: {
      marginRight: theme.normalize(4),
      flex: 1,
    },
  });
