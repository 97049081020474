import React from "react";
import { JText, Spacer } from "../../jlib";

type Props = {};

export const SubscribeContent: React.FC<Props> = ({}) => {
  return (
    <>
      <JText centered isBold labelKey="auth.messages" titleLevel={2} />
      <Spacer height="mediumLarge" backgroundColor="transparent" />
      <JText labelKey="modal.userMessageRights" sizeName="mediumLarge" />
    </>
  );
};
