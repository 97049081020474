import { REACT_APP_API_ROOT } from "../constants";
import * as Linking from "expo-linking";
import { Platform } from "react-native";

export const downloadFile = async (document: any) => {
  const fileUri = `${REACT_APP_API_ROOT}${document?.url || document}`; // Replace with the URL of the file you want to download

  if (Platform.OS === "web") {
    window.open(fileUri, "_blank");
  } else {
    Linking.openURL(fileUri);
  }
};

export const getDocumentType = (extension: string) => {
  switch (extension) {
    case ".jpg":
    case ".png":
    case "jpeg": // comme on regarde les 4 derniers caractères il n'y a pas de point
      return "Image";
    case ".pdf":
      return "Document PDF";
    case ".mp3":
    case ".mp4":
      return "Vidéo";
    default:
      return "Fichier texte";
  }
};
