import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DimensionValue, StyleProp, View, ViewStyle } from "react-native";
import { JText, JTextInput } from "../../index";

type Props = {
  isUppercaseMandatory?: boolean;
  isLowercaseMandatory?: boolean;
  isNumberMandatory?: boolean;
  isSymbolMandatory?: boolean;
  minimumLength?: number;
  value?: string;
  setPassword?: any;
  onChangeValue: React.Dispatch<React.SetStateAction<string>>;
  setIsPasswordValid: any;
  isPasswordValid: boolean;
  placeHolder?: any;
  props?: any;
  style?: StyleProp<ViewStyle> | null;
  containerStyle?: StyleProp<ViewStyle>;
};

export const Password: React.FC<Props> = ({
  isUppercaseMandatory = true,
  isLowercaseMandatory = true,
  isNumberMandatory = true,
  isSymbolMandatory = true,
  onChangeValue,
  setIsPasswordValid,
  isPasswordValid,
  value = "",
  placeHolder = "",
  minimumLength = 8,
  containerStyle,
  props = {},
}: Props) => {
  const { t } = useTranslation();

  const isPasswordCorrect = useCallback(
    (password: string | undefined) => {
      if (!password) return false;

      const isUppercase = password.match(/[A-Z]/g);
      const isLowercase = password.match(/[a-z]/g);
      const isNumber = password.match(/[0-9]/g);
      const isSymbol = password.match(/[^\w\s]/g);
      let isError = false;

      if (isUppercaseMandatory && isUppercase === null) isError = true;
      if (isLowercaseMandatory && isLowercase === null) isError = true;
      if (isNumberMandatory && isNumber === null) isError = true;
      if (isSymbolMandatory && isSymbol === null) isError = true;
      if (password.length < minimumLength) isError = true;

      return !isError;
    },
    [
      isUppercaseMandatory,
      isLowercaseMandatory,
      isNumberMandatory,
      isSymbolMandatory,
      minimumLength,
    ]
  );

  const getStrength = useCallback(() => {
    if (!value) return 0;

    const isUppercase = value.match(/[A-Z]/g);
    const isLowercase = value.match(/[a-z]/g);
    const isNumber = value.match(/[0-9]/g);
    const isSymbol = value.match(/[^\w\s]/g);
    let passwordStrength: any = 0;

    if (isUppercase && isLowercase && isNumber && isSymbol) {
      passwordStrength = 6.49;
    } else if (isUppercase && isLowercase && isNumber) {
      passwordStrength = 5.95;
    } else if (isUppercase && isLowercase && isSymbol) {
      passwordStrength = 6.1;
    } else if (isUppercase && isLowercase) {
      passwordStrength = 5.7;
    } else if ((isUppercase || isLowercase) && isNumber) {
      passwordStrength = 5.17;
    } else if (isUppercase || isLowercase) {
      passwordStrength = 4.7;
    } else if (isNumber) {
      passwordStrength = 3.32;
    }

    // If something is missing, keep the score low
    if (!isPasswordCorrect(value)) return 5;

    return Math.round(parseFloat(passwordStrength) * value.length);
  }, [isPasswordCorrect, value]);

  const strength = getStrength();

  const getPasswordColor = useCallback(() => {
    if (strength <= 49) {
      return {
        background: "darkred",
        text: t("common.invalid"),
        textColor: "white",
      };
    }
    if (strength <= 65) {
      return {
        background: "orange",
        text: t("common.medium"),
        textColor: "white",
      };
    }
    if (strength <= 78) {
      return {
        background: "goldenrod",
        text: t("common.quiteStrong"),
        textColor: "white",
      };
    }
    if (strength <= 82) {
      return {
        background: "goldenrod",
        text: t("common.quiteStrong"),
        textColor: "white",
      };
    }
    if (strength <= 104) {
      return {
        background: "yellowgreen",
        text: t("common.strong"),
        textColor: "white",
      };
    }

    return {
      background: "forestgreen",
      text: t("common.veryStrong"),
      textColor: "white",
    };
  }, [strength, t]);

  const checkPassword = useCallback(() => {
    const isUppercase = value.match(/[A-Z]/g);
    const isLowercase = value.match(/[a-z]/g);
    const isNumber = value.match(/[0-9]/g);
    const isSymbol = value.match(/[^\w\s]/g);
    let isError = false;

    if (isUppercaseMandatory && isUppercase === null) isError = true;
    if (isLowercaseMandatory && isLowercase === null) isError = true;
    if (isNumberMandatory && isNumber === null) isError = true;
    if (isSymbolMandatory && isSymbol === null) isError = true;
    if (value.length < minimumLength) isError = true;

    setIsPasswordValid(!isError);
  }, [
    isUppercaseMandatory,
    isLowercaseMandatory,
    isNumberMandatory,
    isSymbolMandatory,
    minimumLength,
    setIsPasswordValid,
    value,
  ]);

  useEffect(() => {
    checkPassword();
    getStrength();
  }, [checkPassword, getStrength, value]);

  const [infos, setInfos] = useState({
    background: "darkred",
    text: t("common.invalid"),
    textColor: "white",
  });

  useEffect(() => {
    setInfos(getPasswordColor());
  }, [getPasswordColor]);

  const passwordStyle: {
    marginTop: number;
    backgroundColor: string;
    width: DimensionValue;
    paddingHorizontal: number;
    paddingVertical: number;
    borderRadius: number;
    minWidth: number;
  } = useMemo(
    () => ({
      marginTop: 10,
      backgroundColor: infos?.background,
      width: `${strength > 100 ? 100 : strength}%`,
      paddingHorizontal: 5,
      paddingVertical: 2,
      borderRadius: 8,
      minWidth: 100,
    }),
    [infos?.background, strength]
  );

  return (
    <View>
      <JTextInput
        icon="key"
        type="password"
        onChangeValue={onChangeValue}
        inputType={"password"}
        placeHolder={placeHolder}
        containerStyle={containerStyle}
        {...props}
      />

      {Boolean(value !== "") && (
        <View style={passwordStyle}>
          <JText
            style={{
              color: infos?.textColor,
            }}
            label={infos?.text}
            isBold
            sizeName="medium"
          />
        </View>
      )}

      {Boolean(value !== "" && !isPasswordValid) && (
        <JText labelKey="auth.passwordError" sizeName="medium" />
      )}
    </View>
  );
};

export default Password;
