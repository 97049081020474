import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import React, { useCallback, useMemo } from "react";
import { useWindowDimensions } from "react-native";
import { Clickable, Icon } from "../components";
import { usePosts } from "../hooks";
import {
  PostDetailsScreen,
  PostsFollowedSites,
  ReactHomeScreen,
  SiteDetailsScreen,
} from "../screens";
import useTheme from "../theme/ThemeProvider";
import fnStyles from "./HeaderStyle";
import { useTranslation } from "react-i18next";
import HeaderTitleWithSubtitle from "./HeaderTitleWithSubtitle";
import getWording from "utils/wording";
import useConfiguration from "../configuration/ConfigurationProvider";
import { FollowedSitesParamsList } from "./FollowedSitesParamsList";
import useSites from "hooks/useSites";

const FollowedSitesStack = createStackNavigator<FollowedSitesParamsList>();

export const FollowedSitesStackNavigator: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();
  const { site } = useSites();
  const { post } = usePosts();

  const isScreenHorizontal = useMemo(
    () => isHorizontalMode({ width, height }),
    [height, width]
  );

  const styles = fnStyles(theme, isScreenHorizontal);

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isScreenHorizontal
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [isScreenHorizontal, theme]
  );

  const headerLeft = useCallback(
    (props: any) => (
      <Clickable onPress={props.onPress!} style={headerLeftStyle}>
        <Icon
          name={"chevron-small-left"}
          type={"Entypo"}
          colorName={"white"}
          sizeName={"high"}
          style={{ marginLeft: theme.sizings.small }}
        />
      </Clickable>
    ),
    [headerLeftStyle, theme]
  );

  const renderHeaderTitleWithSubtitle = useCallback(
    (primaryText?: string, secondaryText?: string) => {
      return (
        <HeaderTitleWithSubtitle
          primaryText={primaryText || ""}
          secondaryText={secondaryText}
        />
      );
    },
    []
  );

  return (
    <FollowedSitesStack.Navigator
      screenOptions={({ route }) => ({
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign:
          route.name === "followedSitesHome" || route.name === "chatSelectType"
            ? "center"
            : "left",
        headerShown: route.name !== "chatSelectType",
      })}
    >
      <FollowedSitesStack.Screen
        name={"followedSitesHome"}
        component={PostsFollowedSites}
        options={{
          title:
            t("header.myFollowed", {
              wording: getWording(configuration).plural,
            }) ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
        }}
      />
      <FollowedSitesStack.Screen
        name={"siteDetails"}
        component={SiteDetailsScreen}
        options={() => ({
          title: `${site?.name}` ?? t("header.siteDetails") ?? "",
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(site?.name, site?.location),
          headerLeft: headerLeft,
          headerStyle: site?.mainImage
            ? styles.containerStraight
            : styles.container,
        })}
      />
      <FollowedSitesStack.Screen
        name={"postDetails"}
        component={PostDetailsScreen}
        options={() => ({
          title: t("header.postDetails") ?? "",
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(post?.title, post?.site?.name),
          headerLeft: headerLeft,
          headerStyle: post?.mainImage
            ? styles.containerStraight
            : styles.container,
        })}
      />
      <FollowedSitesStack.Screen
        name={"chatSelectType"}
        component={ReactHomeScreen}
        options={{
          title: t("header.react") ?? "",
          headerLeft: headerLeft,
          headerStyle: post?.mainImage
            ? styles.containerStraight
            : styles.container,
        }}
      />
    </FollowedSitesStack.Navigator>
  );
};
