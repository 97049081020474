import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ResetPasswordEmailScreenStyle";

type Props = StackScreenProps<ParamsParamsList, "resetPasswordEmail">;

export const ResetPasswordEmailScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const [email, setEmail] = useState("");

  const { forgotPassword, errorMessage, cleanError } = useUser();

  useEffect(() => {
    cleanError();
  }, [cleanError]);

  const onSubmit = async () => {
    const res = await forgotPassword(email);
    if (res && !res?.error) {
      navigation.navigate("resetPasswordEmailCheck");
    }
    return res;
  };

  const onBack = () => {
    navigation.navigate("logIn");
  };

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.reinitPasswordTitle"
              isBold
              centered
              titleLevel={2}
              titleLevelStyle={2}
            />
            <JText
              labelKey="auth.reinitPasswordMailInfo"
              sizeName={"mediumLarge"}
              style={{ marginTop: theme.normalize(theme.sizings.medium) }}
            />
            <JTextInput
              value={email}
              onChangeValue={setEmail}
              icon="envelope"
              placeHolder={t("common.email")}
              keyboardType="email-address"
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
            />

            {/* note: we don't display a real error because of privacy, but we use it to display a neutral message after submitting */}
            {errorMessage ? (
              <>
                <Spacer height="medium" backgroundColor={"transparent"} />
                <JText
                  labelKey="auth.maybeSendMail"
                  colorName={"dark"}
                  sizeName="mediumLarge"
                  centered
                />
                <Spacer height="small" backgroundColor={"transparent"} />
                <Button
                  type={"gradient"}
                  labelKey="auth.back"
                  onPress={onBack}
                  containerStyle={styles.button}
                />
              </>
            ) : (
              <Button
                type={"gradient"}
                labelKey="common.validate"
                onPress={onSubmit}
                containerStyle={styles.button}
              />
            )}
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
