import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { I18nKey } from "../../../../i18n";
import useTheme from "../../../theme/ThemeProvider";
import { Types } from "../../../types";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import { Row } from "../row";
import { JText } from "../text";
import fnStyles from "./ButtonStyle";
import { ColorType, FontSizeType } from "theme/ThemeTypes";

type Props = {
  label?: string;
  labelKey?: I18nKey;
  valuesKey?: object;
  onPress: () => void;
  type?: Types["buttonTypes"];
  iconType?: Types["iconTypes"];
  iconName?: string;
  iconSizeName?: FontSizeType;
  iconColorName?: ColorType;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  gradientColors?: [ColorType];
  outlined?: boolean;
};

export const Button: React.FC<Props> = ({
  label,
  labelKey,
  valuesKey = {},
  onPress,
  type = "main",
  iconType = "AntDesign",
  iconName,
  iconSizeName,
  iconColorName,
  style = {},
  containerStyle = {},
  textStyle = {},
  disabled = false,
  gradientColors,
  outlined = false,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  switch (type) {
    case "action":
      return (
        <Clickable
          style={[
            disabled ? styles.disabledContainer : styles.actionContainer,
            style,
          ]}
          onPress={onPress}
          disabled={disabled}
        >
          <Row align={"center"}>
            <JText
              label={label}
              labelKey={labelKey}
              valuesKey={valuesKey}
              isButtonText
              style={[
                disabled ? styles.disabledText : styles.actionText,
                textStyle,
              ]}
              centered
            />
            {iconName && (
              <View style={styles.iconContainer}>
                <Icon
                  name={iconName || "like1"}
                  type={iconType}
                  sizeName={iconSizeName}
                  colorName={iconColorName ?? "gradientText"}
                />
              </View>
            )}
          </Row>
        </Clickable>
      );
    case "main":
      return (
        <Clickable
          style={[
            disabled
              ? styles.disabledContainer
              : outlined
              ? styles.mainOutlinedContainer
              : styles.mainContainer,
            style,
          ]}
          onPress={onPress}
          disabled={disabled}
        >
          <JText
            label={label}
            labelKey={labelKey}
            valuesKey={valuesKey}
            isButtonText
            style={[disabled ? styles.disabledText : styles.text, textStyle]}
          />
        </Clickable>
      );
    case "gradient":
      return (
        <Clickable onPress={onPress} disabled={disabled} style={containerStyle}>
          <LinearGradient
            colors={
              gradientColors ?? disabled
                ? [theme.colors.grey, theme.colors.grey]
                : [theme.colors.gradientStart, theme.colors.gradientEnd]
            }
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 1 }}
            style={[
              disabled ? styles.disabledContainer : styles.gradientContainer,
              style,
            ]}
          >
            {iconName ? (
              <Row align={"center"}>
                <View style={styles.iconContainerGradient}>
                  <Icon
                    name={iconName || "like1"}
                    type={iconType}
                    sizeName={iconSizeName}
                    colorName={iconColorName ?? "gradientText"}
                    style={styles.gradientIcon}
                  />
                </View>

                <JText
                  label={label}
                  labelKey={labelKey}
                  valuesKey={valuesKey}
                  style={[
                    disabled ? styles.disabledText : styles.gradientText,
                    textStyle,
                  ]}
                  isButtonText
                />
              </Row>
            ) : (
              <JText
                label={label}
                labelKey={labelKey}
                valuesKey={valuesKey}
                style={[
                  disabled ? styles.disabledText : styles.gradientText,
                  textStyle,
                ]}
                isButtonText
              />
            )}
          </LinearGradient>
        </Clickable>
      );
  }
};
