import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Role,
  StyleProp,
  Text,
  TextStyle,
  useWindowDimensions,
} from "react-native";
import { I18nKey } from "../../../../i18n";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, FontSizeType } from "theme/ThemeTypes";
import { Types } from "../../../types";
import { isHorizontalMode } from "components/utils/deviceMode";

type Props = {
  label?: string;
  labelKey?: I18nKey;
  valuesKey?: object;
  colorName?: ColorType;
  fontWeight?: Types["fontWeights"];
  sizeName?: FontSizeType;
  isUppercase?: boolean;
  isBold?: boolean;
  isItalic?: boolean;
  centered?: boolean;
  right?: boolean;
  isTitleText?: boolean;
  isButtonText?: boolean;
  numberOfLines?: number;
  role?: Role;
  nativeID?: string;
  titleLevel?: number;
  titleLevelStyle?: number;
  forceSize?: boolean;
  style?: StyleProp<TextStyle>;
};

export const JText = ({
  label,
  labelKey,
  valuesKey = {},
  colorName,
  fontWeight,
  sizeName,
  isUppercase = false,
  isBold = false,
  isItalic = false,
  centered = false,
  right = false,
  isTitleText = false,
  isButtonText = false,
  numberOfLines = 0,
  nativeID,
  titleLevel,
  titleLevelStyle,
  forceSize,
  style,
}: Props) => {
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();

  const { theme } = useTheme();

  const defaultSizeMultiply = useMemo(() => {
    if (forceSize || !isHorizontalMode({ width, height })) {
      return 1;
    }

    if ((titleLevel === 1 && !titleLevelStyle) || titleLevelStyle === 1) {
      return 1.3;
    }

    if (titleLevel === 2 || titleLevelStyle === 2) {
      return 1.2;
    }
    if (titleLevel === 3 || titleLevelStyle === 3) {
      return 1.15;
    }

    return 1;
  }, [forceSize, titleLevel, titleLevelStyle, width, height]);

  const font = () => {
    const defaultColor = theme.colors.dark;
    const defaultSize = theme.normalize(theme.fontSizes.large);

    if (isTitleText) {
      return {
        fontFamily: theme.fonts.fontTitle,
        fontSize: sizeName
          ? theme.normalize(theme.fontSizes[sizeName]) * defaultSizeMultiply
          : defaultSize * defaultSizeMultiply,
        fontWeight: fontWeight || "600",
        marginBottom: theme.normalize(theme.sizings.large),
        color: colorName ? theme.colors[colorName] : theme.colors.dark,
      };
    }

    if (isButtonText) {
      return {
        fontFamily: theme.fonts.fontTitle,
        fontSize: sizeName
          ? theme.normalize(theme.fontSizes[sizeName])
          : theme.normalizeFont(18),
        fontWeight: fontWeight || "600",
        color: colorName ? theme.colors[colorName] : theme.colors.primary,
      };
    }

    return {
      fontSize: sizeName
        ? theme.normalize(theme.fontSizes[sizeName]) * defaultSizeMultiply
        : defaultSize * defaultSizeMultiply,
      color: colorName ? theme.colors[colorName] : defaultColor,
    };
  };

  const templateFont = font();

  const jTextStyle: StyleProp<TextStyle> = useMemo(
    () => [
      isBold
        ? { fontWeight: "700" }
        : isButtonText
        ? { fontWeight: "600" }
        : { fontWeight },

      isUppercase && { textTransform: "uppercase" },
      templateFont,

      {
        textAlign: centered ? "center" : right ? "right" : "left",
        fontStyle: isItalic ? "italic" : "normal",
      },
      style,
    ],
    [
      centered,
      fontWeight,
      isBold,
      isButtonText,
      isItalic,
      isUppercase,
      right,
      style,
      templateFont,
    ]
  );

  const labelResult = (labelKey ? t(labelKey, valuesKey) : label) as I18nKey;

  return (
    <Text
      style={jTextStyle}
      numberOfLines={numberOfLines}
      nativeID={nativeID}
      role={titleLevel ? "heading" : undefined}
      aria-level={titleLevel}
    >
      {labelResult}
    </Text>
  );
};
