import { StyleProp, ViewStyle, Platform } from "react-native";
import React from "react";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType } from "theme/ThemeTypes";
import { Types } from "../../../types";
import { JSwitch } from "../../jlib";

type Props = {
  value: boolean;
  onToggle: (boolean: any) => void;
  disabled?: boolean;
  iconType?: Types["iconTypes"];
  iconName?: string;
  iconCustomSize?: number;
  iconColorName?: ColorType;
  iconStyle?: StyleProp<ViewStyle>;
  accessibilityLabel?: string;
  accessibilityLabelledBy?: string;
  style?: StyleProp<ViewStyle> | null;
};

export const SwitchWithIcon: React.FC<Props> = ({
  value,
  onToggle,
  disabled = false,
  iconType = "AntDesign",
  iconName = "like1",
  iconCustomSize,
  iconColorName = "white",
  accessibilityLabel,
  accessibilityLabelledBy,
}: Props) => {
  const { theme } = useTheme();
  const showIcon = Platform.OS !== "android";

  return (
    <JSwitch
      value={value}
      onToggle={onToggle}
      onColor={theme.colors.info}
      offColor={theme.colors.grey}
      disabled={disabled}
      // icon
      iconName={showIcon ? iconName : undefined}
      iconType={showIcon ? iconType : undefined}
      iconColorName={showIcon ? iconColorName ?? "transparent" : undefined}
      iconCustomSize={showIcon ? iconCustomSize : undefined}
      // accesssibility
      accessibilityLabel={accessibilityLabel}
      accessibilityLabelledBy={accessibilityLabelledBy}
    />
  );
};

export default SwitchWithIcon;
