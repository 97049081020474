import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  fetchJSON,
  getStoredData,
  parseStrapiFormat,
  removeStoredData,
  storeData,
} from "../utils";
// import { useTranslation } from "react-i18next";
import { isHorizontalMode } from "components/utils/deviceMode";
import usePersistedState from "components/utils/usePersistedState";
import { Platform, useWindowDimensions } from "react-native";
import { Site } from "types/Site";
import {
  REACT_APP_FRONTEND,
  REACT_APP_API_ENDPOINT,
  REACT_APP_ORGANIZATION_ID,
  SPECIFIC_THEME,
} from "../constants";
import { AnonymousUser } from "../types/AnonymousUser";
import { User, UserType } from "../types/User";
import useNotification from "./NotificationProvider";
import useOrganization from "hooks/useOrganization";
import globals from "utils/globals";
import { useTranslation } from "react-i18next";
import useConfiguration from "./../configuration/ConfigurationProvider";

type ProviderUserContextType = {
  user?: User;
  anonymousUser?: AnonymousUser;
  token?: string;
  signin: (identifier: string, password: string) => Promise<boolean | User>;
  refreshUser: () => Promise<User>;
  register: (values: any) => Promise<{ jwt: string; user: User }>;
  updateUser: (payload: any) => Promise<User | null>;
  deleteUser: (userId: string) => Promise<User | null>;
  isFetching: boolean;
  isRefreshFetching: boolean;
  isSignInError: boolean;
  errorMessage: string | undefined;
  cleanError: () => void;
  signout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<any>;
  resetPassword: (password: string, code: string) => Promise<boolean>;
  refreshAnonymousUser: () => Promise<AnonymousUser>;
  updateAnonymousUser: (payload: any) => Promise<AnonymousUser>;
  deleteAnonymousUser: (userId: string) => Promise<boolean>;
  localHistoryThanksPosts: Array<number>;
  setLocalHistoryThanksPosts: (
    value: Array<number> | ((value: Array<number>) => void)
  ) => void;
  displayIntroduction: boolean;
  setDisplayIntroduction: (value: boolean | ((value: boolean) => void)) => void;
  followedSites: Array<number>;
  updateFollowedSites: (site: any) => void;
  areaOfInterest: any;
  updateAreaOfInterest: any;
  notificationSettings: any;
  updateNotificationSettings: any;
  displayCtaWarning: boolean;
  setDisplayCtaWarning: (value: boolean | ((value: boolean) => void)) => void;
  isFollowChanging: number;
  setIsFollowChanging: any;
  isParamItemChanging: string;
  setIsParamItemChanging: any;
  checkNavShow: (state: any) => boolean;
  allowedOrganizationForUser: number[];
  isUserAllowed: () => boolean;
  isLoginMandatory: boolean;
  isAppLoadedAndDisplayable: boolean;
  appHasLoadOnce: boolean;
  enableSmsNotifications: boolean;
  fetchUserTypes: () => void;
  userTypes: UserType[];
};

export const UserContext = createContext<ProviderUserContextType>(
  {} as ProviderUserContextType
);

type Props = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const { configuration } = useConfiguration();
  const isLoginMandatory = Boolean(
    configuration.profile.features.loginMandatory &&
      /^true$|^1$/i.test(
        configuration.profile.features.loginMandatory.toString()
      )
  );

  const [localHistoryThanksPosts, setLocalHistoryThanksPosts] =
    usePersistedState([], "local--history-thanks-posts");

  const [displayIntroduction, setDisplayIntroduction] = usePersistedState(
    true,
    "local--display-introduction",
    true
  );

  const [displayCtaWarning, setDisplayCtaWarning] = usePersistedState(
    true,
    "local--display-cta-warning"
  );

  const [localNotificationSettings, setLocalNotificationSettings] =
    usePersistedState({}, "local--notification-settings");

  const { refreshOrganization, organization } = useOrganization();
  const appOrganizationId = parseInt(REACT_APP_ORGANIZATION_ID, 10) || null;

  //const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const [user, setUser] = useState<User>();
  const [appHasLoadOnce, setAppHasLoadOnce] = useState(false);
  const [anonymousUser, setAnonymousUser] = useState<AnonymousUser>();
  const [currentJwt, setCurrentJwt] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshFetching, setIsRefreshFetching] = useState(false);
  const [isSignInError, setIsSignInError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isFollowChanging, setIsFollowChanging] = useState(-1);
  const [isParamItemChanging, setIsParamItemChanging] = useState("");
  const [userTypes, setUserTypes] = useState([]);

  const { pushToken } = useNotification();

  const [localFollowedSites, setLocalFollowedSites] = usePersistedState(
    [],
    "local--followed-sites"
  );
  const [allowedOrganizationForUser, setAllowedOrganizationForUser] = useState<
    number[]
  >([]);

  const isAppLoadedAndDisplayable = useMemo(() => {
    return displayIntroduction !== null && !isRefreshFetching;
  }, [displayIntroduction, isRefreshFetching]);

  useEffect(() => {
    if (isAppLoadedAndDisplayable && !appHasLoadOnce) {
      setAppHasLoadOnce(true);
    }
  }, [appHasLoadOnce, isAppLoadedAndDisplayable]);

  const enableSmsNotifications = useMemo(() => {
    if (Platform.OS !== "web") {
      return false;
    }
    const noPush = !pushToken;
    const allowSmsModule = !organization || organization?.activeModuleSms;
    const activeSmsFeature = configuration.profile.phone.sms;

    return Boolean(noPush && allowSmsModule && activeSmsFeature);
  }, [configuration, organization, pushToken]);

  const followedSites: number[] = useMemo(() => {
    if (user?.id) {
      return (
        user.followedSites?.filter((fs) => fs.active).map(({ id }) => id) || []
      );
    } else if (anonymousUser?.id) {
      return (
        anonymousUser?.followedSites
          ?.filter((s) => s.active)
          ?.map(({ id }) => id) || []
      );
    } else {
      return localFollowedSites || [];
    }
  }, [anonymousUser, localFollowedSites, user]);
  const isUserAllowed = useCallback(() => {
    return REACT_APP_ORGANIZATION_ID
      ? allowedOrganizationForUser.includes(
          parseInt(REACT_APP_ORGANIZATION_ID, 10)
        )
      : true;
  }, [allowedOrganizationForUser]);
  const notificationSettings: any = useMemo(() => {
    if (user?.id) {
      return user.notificationSettings;
    } else if (anonymousUser?.id) {
      return anonymousUser?.notificationSettings;
    } else {
      return localNotificationSettings;
    }
  }, [anonymousUser, localNotificationSettings, user]);

  const areaOfInterest: any = useMemo(() => {
    if (user?.id) {
      return user.areaOfInterest;
    } else if (anonymousUser?.id) {
      return null;
    } else {
      return null;
    }
  }, [anonymousUser, user]);

  const updateAnonymousUser = useCallback(
    async (payload: any) => {
      if (pushToken) {
        setIsFetching(true);
        payload.pushToken = pushToken;
        try {
          const res = await fetchJSON({
            url: "anonymous-users/add",
            method: "POST",
            payload,
          });

          if (res) {
            setAnonymousUser(res);
            return res;
          } else {
            return null;
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsFetching(false);
        }
      }
    },
    [pushToken]
  );

  const deleteAnonymousUser = useCallback(
    async (userId: string) => {
      if (pushToken) {
        try {
          setIsFetching(true);
          return fetchJSON({
            url: `anonymous-users/${userId}`,
            method: "DELETE",
          });
        } catch {
          return;
        } finally {
          setIsFetching(false);
        }
      }
    },
    [pushToken]
  );

  const signout = useCallback(async () => {
    const userId = await getStoredData("userId");

    if (pushToken && userId && Platform.OS !== "web") {
      fetchJSON({
        url: `users/${userId}`,
        method: "PUT",
        payload: { pushToken: "" },
      });
    }
    // On reset l'organization forcée manuellement au cas où
    globals.ORGANIZATION_ID = "";

    setAllowedOrganizationForUser([]);
    setUser(undefined);
    setCurrentJwt(undefined);
    await removeStoredData("token");
    await removeStoredData("user");
  }, [pushToken]);

  const refreshAnonymousUser = useCallback(async () => {
    try {
      if (pushToken) {
        setIsRefreshFetching(true);
        const res = await fetchJSON({
          url: `anonymous-users/${pushToken}?populate[0]=followedSites`,
          method: "GET",
        });

        //setIsRefreshFetching(false);
        const newAnonymousUser = parseStrapiFormat(res);
        setAnonymousUser(newAnonymousUser);
        return newAnonymousUser;
      }

      return null;
    } catch {
      return null;
    } finally {
      setIsRefreshFetching(false);
    }
  }, [pushToken]);

  const refreshUser = useCallback(async () => {
    try {
      const token = await getStoredData("token");

      if (token) {
        setIsRefreshFetching(true);
        const res = await fetchJSON({
          url: "users/me",
          method: "GET",
          jwt: token,
        });
        if (res) {
          if (pushToken) {
            if (pushToken !== res.pushToken) {
              fetchJSON({
                url: `users/${res.id}`,
                method: "PUT",
                payload: { pushToken },
              });
              res.pushToken = pushToken;
            }
          }
          setCurrentJwt(token);
          setUser(res);

          const userOrganizations = res.is_contact_in_organizations || [];

          // remember for later use
          setAllowedOrganizationForUser(userOrganizations);

          return res;
        }
      }

      signout();
      return null;
    } catch {
      // if users/me is not accessible then the token expired
      signout();
      return null;
    } finally {
      setIsRefreshFetching(false);
    }
  }, [pushToken, signout]);

  useEffect(() => {
    if (pushToken && user) {
      if (pushToken !== user.pushToken) {
        fetchJSON({
          url: `users/${user.id}`,
          method: "PUT",
          payload: { pushToken },
        });
        user.pushToken = pushToken;
      }
      setUser(user);
    }
  }, [pushToken, user]);

  const signin = useCallback(
    async (email: string, password: string): Promise<boolean | User> => {
      const payload = {
        identifier: email,
        password,
      };
      setIsSignInError(false);
      setIsFetching(true);

      try {
        const res = await fetch(`${REACT_APP_API_ENDPOINT}/auth/local`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();

        if (res.status === 200) {
          try {
            // partie spéciale pour toutes les applications privées
            if (isLoginMandatory) {
              // on récupère les organisations dont l'utilisateur est un contact
              const userOrganizations =
                resJson.is_contact_in_organizations || [];

              // remember for later use
              setAllowedOrganizationForUser(userOrganizations);

              if (appOrganizationId) {
                // LOGIN=TRUE + ORGA=TRUE
                if (userOrganizations.includes(Number(appOrganizationId))) {
                  console.info("User is in the organization contacts.");
                  // Le refreshOrganization est déjà fait et on ne change pas d'organization.
                } else {
                  console.info("User is NOT in the organization contacts.");
                  // on bannit l'utilisateur sans explications pour la sécurité
                  /* setIsFetching(false);
                  setIsSignInError(true);
                  setErrorMessage("Login incorrect");
                  return false; */
                }
              } else {
                // LOGIN=TRUE + ORGA=FALSE
                if (userOrganizations.length > 0) {
                  // Refresh de l'organization utilisée pour ce user
                  // (Le premier de la liste par défaut)
                  console.info(
                    `Refreshing user's first organization (${userOrganizations[0]}).`
                  );
                  // Dans ce cas précis, on force manuellement l'organization :
                  globals.ORGANIZATION_ID = `${userOrganizations[0]}`;
                  await refreshOrganization(Number(userOrganizations[0]));
                } else {
                  console.info("User has NO organization.");
                  // Pas d'organisation ? Pas de pb, c'est pas obligatoire.
                }
              }
            }

            // puis comportement normal
            await storeData("token", resJson.jwt);
            await storeData("userId", resJson.user.id.toString());
            setCurrentJwt(resJson.jwt);
            return await refreshUser();
          } catch {
            setIsSignInError(true);
            setErrorMessage(resJson.error.message);
            return true;
          }
        }
        setIsSignInError(true);
        setErrorMessage(resJson.error.message);
        return true;
      } catch (err: any) {
        console.log({ err });
        setErrorMessage(err?.message);
        setIsSignInError(true);
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    [isLoginMandatory, refreshUser, appOrganizationId, refreshOrganization]
  );

  const register = useCallback(
    async (values: User): Promise<{ jwt: string; user: User }> => {
      const payload = {
        ...values,
        pushToken,
        followedSites: [...localFollowedSites],
      };

      setIsFetching(true);
      try {
        const res = await fetch(
          `${REACT_APP_API_ENDPOINT}/auth/local/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const resJson = await res.json();

        if (res.status === 200) {
          /* jwt n'est pas généré et ne sert à rien à cette étape, ça a donc l'air d'un copié-collé de "signin"
          /*await storeData("token", resJson.jwt);
          await storeData("userId", resJson.user.id.toString());
          setCurrentJwt(resJson.jwt);*/
          return resJson;
        }
        let errorMsg: any = "Erreur serveur";
        console.error({ error: resJson?.error?.message, resJson });
        if (resJson?.error?.message === "Email or Username are already taken") {
          errorMsg = t("auth.alreadyUsedEmail");
        }
        setErrorMessage(errorMsg);

        throw new Error(errorMsg);
      } finally {
        setIsFetching(false);
      }
    },
    [pushToken, t, localFollowedSites]
  );

  const cleanError = useCallback(() => {
    setIsSignInError(false);
    setErrorMessage(undefined);
  }, []);

  const checkPhone = (value: any) => {
    const userNum = value.replace(/^(\+|00)330?/, "0"); // "0033 6", "+336" => 06
    const isMobilePhone = userNum.match(/^(06|07)[0-9]{8}$/);
    const isFixPhone = userNum.match(/^(01|02|03|04|05|08|09)[0-9]{8}$/);
    return Boolean(isMobilePhone || isFixPhone);
  };

  const updateUser = useCallback(
    async (payload: any) => {
      const token = await getStoredData("token");

      let isCorrectPhone = true;

      if (payload.phoneNumber?.length) {
        isCorrectPhone = checkPhone(payload.phoneNumber);
      }

      if (!isCorrectPhone) {
        setErrorMessage(t("auth.badPhone"));
        return false;
      }

      if (token) {
        setIsFetching(true);
        try {
          const res = await fetchJSON({
            url: `users/${user?.id}`,
            method: "PUT",
            payload,
            jwt: token,
          });

          setUser(res);

          if (res) {
            return res;
          } else {
            return null;
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsFetching(false);
        }
      }
    },
    [user, t]
  );

  const deleteUser = useCallback(async (userId: string) => {
    const token = await getStoredData("token");

    if (token) {
      setIsFetching(true);
      try {
        const res = await fetchJSON({
          url: `users/${userId}`,
          method: "DELETE",
          jwt: token,
        });

        if (res) {
          setUser(undefined);
          setCurrentJwt(undefined);
          return res;
        } else {
          return null;
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetching(false);
      }
    }
  }, []);

  const forgotPassword = useCallback(
    async (email: string): Promise<any> => {
      const payload = {
        email,
        origin: REACT_APP_FRONTEND,
        application: SPECIFIC_THEME || "informez-moi",
      };

      setIsFetching(true);
      try {
        const res = await fetch(
          `${REACT_APP_API_ENDPOINT}/auth/forgot-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const resJson = await res.json();

        setErrorMessage(t("auth.maybeSendMail"));

        return resJson;
      } catch {
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    [setErrorMessage, setIsFetching, t]
  );

  const resetPassword = useCallback(
    async (password: string, code: string): Promise<boolean> => {
      const payload = {
        code,
        password,
        passwordConfirmation: password,
      };

      setIsFetching(true);
      try {
        const res = await fetch(
          `${REACT_APP_API_ENDPOINT}/auth/reset-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        await res.json();
        if (res.status === 200) {
          return true;
        }
        console.error("Erreur lors du changement de mot de passe");
      } catch (e) {
        console.error("Erreur lors du changement de mot de passe");
      } finally {
        setIsFetching(false);
      }
      return false;
    },
    []
  );

  const updateFollowedSites = useCallback(
    async (site: Site | Site[]) => {
      const isSiteAnArray = Array.isArray(site);
      setIsFollowChanging(Number(isSiteAnArray ? -1 : site.id));
      if (user) {
        let tmpFollowed = user?.followedSites ? [...user.followedSites] : [];

        if (isSiteAnArray) {
          site.forEach((s) => {
            if (user?.followedSites?.find((fS) => fS.id === s.id)) {
              tmpFollowed = tmpFollowed.filter((f: any) => f.id !== s.id);
            } else {
              tmpFollowed.push(s);
            }
          });
        } else {
          if (user?.followedSites?.find((fS) => fS.id === site.id)) {
            tmpFollowed = tmpFollowed.filter((f: any) => f.id !== site.id);
          } else {
            tmpFollowed.push(site);
          }
        }
        await updateUser({ followedSites: tmpFollowed });
      } else if (anonymousUser || pushToken) {
        let tmpFollowed = anonymousUser?.followedSites
          ? [...anonymousUser.followedSites]
          : localFollowedSites;

        if (isSiteAnArray) {
          site.forEach((s) => {
            if (anonymousUser?.followedSites?.find((fS) => fS.id === s.id)) {
              tmpFollowed = tmpFollowed.filter((f: any) => f.id !== s.id);
            } else {
              tmpFollowed.push(s);
            }
          });
        } else {
          if (anonymousUser?.followedSites?.find((fS) => fS.id === site.id)) {
            tmpFollowed = tmpFollowed.filter((f: any) => f.id !== site.id);
          } else {
            tmpFollowed.push(site);
          }
        }

        await updateAnonymousUser({
          followedSites: tmpFollowed,
          notificationSettings: notificationSettings,
        });
        refreshAnonymousUser();
      } else {
        let tmpFollowed = Array.isArray(followedSites)
          ? [...followedSites]
          : [];
        if (isSiteAnArray) {
          site.forEach((s) => {
            if (tmpFollowed?.find((fS: any) => fS === s.id)) {
              tmpFollowed = tmpFollowed?.filter((f: any) => f !== s.id);
            } else {
              tmpFollowed.push(Number(s.id));
            }
          });
        } else {
          if (tmpFollowed?.find((fS: any) => fS === site.id)) {
            tmpFollowed = tmpFollowed?.filter((f: any) => f !== site.id);
          } else {
            tmpFollowed.push(Number(site.id));
          }
        }
        setLocalFollowedSites(tmpFollowed);
      }
      setIsFollowChanging(-1);
    },
    [
      user,
      anonymousUser,
      pushToken,
      updateUser,
      localFollowedSites,
      updateAnonymousUser,
      notificationSettings,
      refreshAnonymousUser,
      followedSites,
      setLocalFollowedSites,
      setIsFollowChanging,
    ]
  );

  const updateNotificationSettings = useCallback(
    async (payload: any, type: string) => {
      setIsParamItemChanging(type);
      if (user) {
        const res = await updateUser({ notificationSettings: payload });

        if (!res) {
          console.error("error updating notification settings");
          return;
        }
      } else if (anonymousUser || pushToken) {
        const res = await updateAnonymousUser({
          notificationSettings: payload,
          followedSites: followedSites,
        });

        if (!res) {
          console.error("error updating notification settings");
          return;
        }
      } else {
        setLocalNotificationSettings(payload);
      }
      setIsParamItemChanging("");
    },
    [
      user,
      anonymousUser,
      pushToken,
      updateUser,
      updateAnonymousUser,
      followedSites,
      setLocalNotificationSettings,
      setIsParamItemChanging,
    ]
  );

  const updateAreaOfInterest = useCallback(
    async (payload: any) => {
      if (user) {
        const res = await updateUser({ areaOfInterest: payload });

        if (!res) {
          console.error("error updating area of interest");
          return;
        }
      } else if (anonymousUser || pushToken) {
        console.error("cannot update anonymous user area of interest");
        return;
      } else {
        console.error("cannot update area of interest");
        return;
      }
    },
    [user, anonymousUser, pushToken, updateUser]
  );

  const checkNavShow = useCallback(
    (state: any) => {
      let shouldShow: boolean = true;

      if (isLoginMandatory && !user) {
        return false;
      }

      if (!isHorizontalMode({ width, height })) {
        const hideNavOnPages = [
          { name: "chat", nameChild: "reactMessage" },
          {
            name: "chat",
            nameChild: "chatSelectUserType",
          },
          {
            name: "chat",
            nameChild: "chatSelectSite",
          },
          {
            name: "chat",
            nameChild: "chatSelectPlace",
          },
          {
            name: "chat",
            nameChild: "chatSelectSiteOrPlace",
          },
          {
            name: "chat",
            nameChild: "contactProfile",
          },
          {
            name: "sites",
            nameChild: "chatSelectType",
            nameChild2: "reactMessage",
          },
          {
            name: "posts",
            nameChild: "chatSelectType",
            nameChild2: "reactMessage",
          },
          { name: "followedSites", nameChild: "siteDetails" },
          { name: "sites", nameChild: "siteDetails" },
          { name: "sites", nameChild: "postDetails" },
          { name: "news", nameChild: "postDetails" },
          { name: "sites", nameChild: "siteSuggestion" },
          {
            name: "news",
            nameChild: "postsFollowedSites",
            nameChild2: "siteDetails",
          },
          {
            name: "news",
            nameChild: "postDetails",
            nameChild2: "siteDetails",
          },
          { name: "params", nameChild: "logIn" },
          { name: "params", nameChild: "signUp" },
          { name: "params", nameChild: "changeInfos" },
          { name: "params", nameChild: "changePassword" },
          { name: "params", nameChild: "resetPasswordEmail" },
          { name: "params", nameChild: "resetPassword" },
          { name: "params", nameChild: "resetPasswordEmailCheck" },
          { name: "params", nameChild: "validateAccount" },
        ];

        hideNavOnPages?.forEach((page) => {
          const lvl1 = state?.routes?.find((r: any) => r.name === page.name);
          const lvl2 = lvl1?.state?.routes?.find(
            (r: any) => r.name === page.nameChild
          );
          const lvl3 = lvl2?.state?.routes?.find(
            (r: any) => r.name === page.nameChild2
          );

          if ((lvl1 && lvl2) || (lvl1 && lvl2 && lvl3)) {
            shouldShow = false;
          }
        });
      }

      return shouldShow;
    },
    [height, width, isLoginMandatory, user]
  );

  const fetchUserTypes = useCallback(async () => {
    try {
      setIsFetching(true);

      const res = await fetchJSON({
        url: "user-types?populate[0]=icon&populate[1]=organization",
        method: "GET",
      });
      if (res) {
        setUserTypes(parseStrapiFormat(res) || []);
        return res;
      }
      return null;
    } catch {
    } finally {
      setIsFetching(false);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        anonymousUser,
        token: currentJwt,
        signin,
        refreshUser,
        register,
        updateUser,
        deleteUser,
        isFetching,
        isRefreshFetching,
        isSignInError,
        errorMessage,
        cleanError,
        signout,
        forgotPassword,
        resetPassword,
        deleteAnonymousUser,
        updateAnonymousUser,
        refreshAnonymousUser,
        localHistoryThanksPosts,
        setLocalHistoryThanksPosts,
        displayIntroduction,
        setDisplayIntroduction,
        followedSites,
        areaOfInterest,
        updateAreaOfInterest,
        notificationSettings,
        updateNotificationSettings,
        displayCtaWarning,
        setDisplayCtaWarning,
        updateFollowedSites,
        isFollowChanging,
        setIsFollowChanging,
        isParamItemChanging,
        setIsParamItemChanging,
        checkNavShow,
        allowedOrganizationForUser: allowedOrganizationForUser,
        isUserAllowed,
        isLoginMandatory,
        isAppLoadedAndDisplayable,
        appHasLoadOnce,
        enableSmsNotifications,
        fetchUserTypes,
        userTypes,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
