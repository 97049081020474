import { Button } from "components/jlib";
import { SubscribeModal } from "components/modals/subscribeModal";
import { isHorizontalMode } from "components/utils/deviceMode";
import { useUser } from "hooks";
import useThread from "hooks/useThread";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./NewChatButtonStyle";
import { BoxedLayout } from "components/common/layout/BoxedLayout";

type Props = {
  handleNav: () => any;
  navigation: any;
  bottomBar?: boolean;
  isReaction?: boolean;
  spanHorizontal?: boolean;
};

export const NewChatButton: React.FC<Props> = ({
  handleNav,
  navigation,
  bottomBar = true,
  isReaction = false,
  spanHorizontal = false,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const { setThread } = useThread();
  const { user } = useUser();

  const styles = fnStyles(
    theme,
    isHorizontalMode({ width, height }),
    bottomBar,
    spanHorizontal
  );

  const [subscribeModalVisible, setSubscribeModalVisible] =
    useState<boolean>(false);

  const handleButtonPressed = useCallback(() => {
    setThread(undefined);
    if (!user) {
      setSubscribeModalVisible(true);
      // peut-être plus tard si c'est une feature demandée
      /*if (Platform.OS === "web") {
        setSubscribeModalVisible(true);
      } else {
        alert(
          "création du user anonyme en même temps qu'on redirige vers le chat"
        );
        onChatNav();
      }*/
    } else {
      handleNav();
    }
  }, [handleNav, setThread, user]);

  return (
    <View style={styles.container}>
      <BoxedLayout>
        <Button
          onPress={handleButtonPressed}
          type={"action"}
          label={
            isReaction ? t("chat.newReacBtnText") : t("chat.newChatBtnText")
          }
          iconType={"IonIcons"}
          iconName={"chatbox"}
          style={styles.button}
          textStyle={styles.buttonLabel}
        />
        <SubscribeModal
          modalVisible={subscribeModalVisible}
          setModalVisible={setSubscribeModalVisible}
          navigation={navigation}
        />
      </BoxedLayout>
    </View>
  );
};
