import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";
import { Column, Icon, JText } from "../jlib";
import fnStyles from "./TimeLineStyle";
import useTheme from "../../theme/ThemeProvider";
import { keyStep } from "../../types/Site";

type Props = {
  keySteps: keyStep[];
  style?: StyleProp<ViewStyle>;
};

export const TimeLine: React.FC<Props> = ({ keySteps, style = {} }: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={style}>
      {keySteps.map((step, k) => {
        return (
          <View key={k} style={styles.stepContainer}>
            <View style={styles.leftContainer}>
              <View
                style={[
                  styles.dot,
                  step.done && styles.validatedDot,
                  (k === 0 || k === keySteps.length - 1) && styles.limitDot,
                  k === keySteps.length - 1 && styles.endDot,
                ]}
              >
                {step.done && (
                  <Icon
                    name={"check-bold"}
                    type={"MaterialCommunity"}
                    colorName={"white"}
                    sizeName={"mediumLarge"}
                  />
                )}
              </View>
              {k !== keySteps.length - 1 && (
                <Column style={styles.lineContainer}>
                  <View style={styles.line} />
                  {/*[...Array(30)].map((item: any, i) => {
                    return (
                      <View
                        style={[
                          styles.lineDot,
                          i === 0 && { marginTop: k % 3 },
                        ]}
                        key={i}
                      />
                    );
                  })*/}
                </Column>
              )}
            </View>
            <View
              style={[
                styles.textContainer,
                (k === 0 || k === keySteps.length - 1) &&
                  styles.limitTextContainer,
              ]}
            >
              <JText
                label={step.name}
                fontWeight={"bold"}
                sizeName={"mediumLarge"}
              />
              <JText
                label={step.description ?? ""}
                style={styles.description}
                sizeName={"mediumLarge"}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
};
