import { isHorizontalMode } from "../../../components/utils/deviceMode";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { Button, Clickable, JText } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./../ParamsScreenStyle";
import { useTranslation } from "react-i18next";

const AccountShouldLoginBlock = ({ navigation }: any) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));

  const onLogInButtonPressed = () => {
    navigation.navigate("logIn");
  };

  const onSignUpButtonPressed = () => {
    navigation.navigate("signUp");
  };

  return (
    <View style={styles.shouldLogin}>
      <Button
        type={"gradient"}
        labelKey="auth.noAccount"
        onPress={onSignUpButtonPressed}
        containerStyle={{
          marginBottom: theme.sizings.mediumLarge,
        }}
      />

      <Clickable onPress={onLogInButtonPressed}>
        <JText
          labelKey={"auth.alreadyAnAccount"}
          colorName={"emphasisText"}
          centered
        />
      </Clickable>

      <JText
        label={t("auth.accountDescription")}
        sizeName={"mediumLarge"}
        style={styles.accountDescription}
        titleLevel={2}
        centered
      />
    </View>
  );
};

export default AccountShouldLoginBlock;
