import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.normalize(theme.sizings.medium),
      padding: 0,
    },
    main: {
      marginRight: theme.sizings.medium,
      flex: 1,
    },
  });
