import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React from "react";
import { BackgroundLayout, Button, Card, JText, SView } from "../../components";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ResetPasswordEmailScreenStyle";

type Props = StackScreenProps<ParamsParamsList, "resetPasswordEmailCheck">;

export const ResetPasswordEmailCheckScreen: React.FC<Props> = ({
  navigation,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const onRedo = async () => {
    navigation.navigate("resetPasswordEmail");
  };

  const onHome = async () => {
    navigation.navigate("paramsHome");
  };

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.confirmMail"
              isBold
              centered
              titleLevel={1}
              titleLevelStyle={2}
            />
            <JText
              labelKey="auth.confirmMailLegende"
              sizeName={"mediumLarge"}
              style={{ marginTop: theme.normalize(theme.sizings.medium) }}
              centered
            />

            <Button
              type={"gradient"}
              labelKey="auth.nothingReceived"
              onPress={onRedo}
              containerStyle={styles.button}
            />
            <Button
              type={"gradient"}
              labelKey="auth.backToHome"
              onPress={onHome}
              containerStyle={styles.button}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
