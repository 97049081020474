import React, { useCallback } from "react";
import {
  View,
  Modal,
  ViewStyle,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { Button, Clickable, JText, Spacer } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
//import fnStyles from "./DeleteAccountModalStyle";
import { useUser } from "../../hooks/useUser";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useTranslation } from "react-i18next";
import useSites from "hooks/useSites";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
};

export const SavePolygonModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const { updateAreaOfInterest } = useUser();
  const { mapPolygonFilter } = useSites();

  const layerStyle = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000095",
    height,
  } as ViewStyle;

  const panelStyle = {
    padding: theme.sizings.large,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.mediumLarge,
    maxWidth: width - theme.sizings.mediumLarge * 2,
  } as ViewStyle;

  const footerStyle = {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  } as ViewStyle;

  const handleOnConfirm = useCallback(() => {
    updateAreaOfInterest(mapPolygonFilter);
    setModalVisible(false);
  }, [mapPolygonFilter, setModalVisible, updateAreaOfInterest]);

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={layerStyle}
        accessibilityRole="button"
        accessibilityLabel={t("modal.deleteAccount")}
      >
        <View style={panelStyle}>
          <BoxedLayout>
            <JText
              labelKey="modal.savePolygon"
              centered
              sizeName="mediumLarge"
              isBold
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <JText
              labelKey="modal.savePolygonDescription"
              centered
              sizeName="mediumLarge"
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <JText
              labelKey="modal.savePolygonWarning"
              centered
              sizeName="mediumLarge"
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <View style={footerStyle}>
              <Button
                type={"gradient"}
                labelKey="common.confirm"
                onPress={handleOnConfirm}
              />
              <Spacer
                height="mediumLarge"
                width="mediumLarge"
                backgroundColor="transparent"
              />
              <Clickable onPress={() => setModalVisible(false)}>
                <JText
                  labelKey="common.cancel"
                  colorName={"emphasisText"}
                  isBold
                  centered
                />
              </Clickable>
            </View>
          </BoxedLayout>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
