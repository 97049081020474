import React from "react";
import {
  View,
  Modal,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { SubscribeContent } from "./contents/SubscribeContent";
import fnStyles from "./ModalStyle";
import { Button, Clickable, JText, Spacer } from "../jlib";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  navigation: any;
};

export const SubscribeModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  navigation,
}) => {
  const { theme } = useTheme();
  const { height, width } = useWindowDimensions();

  const styles = fnStyles(theme, height, width);
  const { t } = useTranslation();

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={styles.layerStyle}
        accessibilityRole="button"
        accessibilityLabel={t("labels.openDocument")}
      >
        <View style={styles.panelStyle}>
          <SubscribeContent />

          <View style={styles.panelFooterStyle}>
            <Clickable onPress={() => setModalVisible(false)}>
              <JText
                labelKey={"modal.skipSubscribeLabel"}
                colorName={"emphasisText"}
                isBold
                centered
              />
            </Clickable>
            <Spacer height="mediumLarge" />
            <Button
              onPress={() => {
                navigation?.reset({
                  index: 0,
                  routes: [
                    {
                      name: "params",
                      state: {
                        routes: [{ name: "signUp" }],
                      },
                    },
                  ],
                });

                setModalVisible(false);
              }}
              labelKey={"modal.confirmSubscribeLabel"}
              type={"gradient"}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
