import React, { useMemo, useRef } from "react";
import { Linking, Platform } from "react-native";
import { Clickable, Icon, JText } from "../../../components";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { REACT_APP_API_ENDPOINT } from "../../../constants";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./LocationHelperStyle";
import { useLocation } from "hooks";
import { useTranslation } from "react-i18next";
import { LinearGradient } from "expo-linear-gradient";

const LocationHelper = ({ disableScroll = false }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { locationData, askCoordinate, setLocationData, setIsLocatedOnce } =
    useLocation();
  const ref = useRef();

  const locationLabel = useMemo(() => {
    return locationData?.label || t("labels.orWriteMyAddress");
  }, [locationData?.label, t]);

  const onFindLocation = async () => {
    const res = await askCoordinate();
    if (res) {
      // @ts-ignore
      ref?.current?.setAddressText(t("labels.yourCurrentLocation"));
    } else {
      if (Platform.OS !== "web") {
        Linking.openSettings();
      }
    }
  };

  return (
    <>
      <Clickable onPress={onFindLocation} style={styles.findLocationButton}>
        <LinearGradient
          colors={[theme.colors.gradientStart, theme.colors.gradientEnd]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 1 }}
          style={styles.gradientContainer}
        >
          <Icon
            type="Entypo"
            name="location-pin"
            colorName="white"
            style={styles.findLocationIcon}
          />
          <JText
            labelKey="labels.findMyLocation"
            colorName="white"
            sizeName="largeLow"
            isButtonText
            style={styles.findLocationButtonText}
          />
        </LinearGradient>
      </Clickable>

      <GooglePlacesAutocomplete
        placeholder={locationLabel}
        // @ts-ignore
        ref={ref}
        fetchDetails
        disableScroll={disableScroll}
        requestUrl={{
          useOnPlatform: "web",
          url: `${REACT_APP_API_ENDPOINT}/sites`,
        }}
        onPress={(data, details: any = null) => {
          setLocationData({
            label: data.description,
            lat: details?.geometry.location.lat || 0,
            lng: details?.geometry.location.lng || 0,
          });
          setIsLocatedOnce(true);
        }}
        textInputProps={{
          placeholderTextColor: locationData?.label
            ? theme.colors.dark
            : theme.colors.grey,
          selectionColor: theme.colors.dark,
        }}
        enablePoweredByContainer={false}
        query={{
          key:
            Platform.OS === "web"
              ? "toto"
              : "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs",
          language: "fr",
          components: "country:fr",
        }}
        styles={{
          container: {
            height: theme.normalize(40),
            width: "100%",
            backgroundColor: theme.colors.white,
          },
          textInputContainer: {
            height: theme.normalize(40),
            backgroundColor: theme.colors.slate,
            borderRadius: theme.normalize(theme.radius.small),
            borderColor: theme.colors.blurBackground,
            borderWidth: 1,
          },
          textInput: {
            backgroundColor: theme.colors.slate,
          },
          listView:
            Platform.OS === "web"
              ? {}
              : {
                  position: "absolute",
                  zIndex: 1000,
                  top: theme.normalize(40),
                },
        }}
      />
    </>
  );
};

export default LocationHelper;
