import React, { useMemo } from "react";
import {
  Modal,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { Button, JImage } from "../jlib";
import { useTranslation } from "react-i18next";
//import fnStyles from "./DocumentModalStyle";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  onDownload: any;
  imageUrl: string;
  subject?: string;
};

export const DocumentModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  onDownload,
  imageUrl,
  subject,
}) => {
  const { theme } = useTheme();
  //const styles = fnStyles(theme);
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();

  const layerStyle = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000095",
    height,
  } as ViewStyle;

  const contentStyle: StyleProp<ViewStyle> = useMemo(
    () => ({
      paddingBottom: theme.sizings.mediumLarge,
      alignItems: "center",
    }),
    [theme]
  );
  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={layerStyle}
        accessibilityRole="button"
        accessibilityLabel={t("labels.openDocument")}
      >
        <View style={contentStyle}>
          <JImage
            source={imageUrl}
            style={{
              width: width - theme.sizings.mediumLarge * 2,
              height:
                height - theme.sizings.mediumLarge * 2 - theme.sizings.huge,
              marginBottom: theme.sizings.mediumLarge,
            }}
            resizeMode={"contain"}
            alt={
              subject
                ? t("titles.imageInModal", { title: subject })
                : t("titles.imageInModalUntitled")
            }
          />

          <Button
            onPress={onDownload}
            labelKey="common.download"
            type={"gradient"}
          />
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
