import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useCallback } from "react";
import {
  BackgroundLayout,
  LoadingScreen,
  NoPosts,
  NoSitesFollowed,
  PostsList,
  SView,
} from "../../components";
import { usePosts, useUser } from "../../hooks";
import { PostsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { Post } from "../../types/Post";
import fnStyles from "./PostsHomeScreenStyle";
import useSites from "hooks/useSites";

type Props = StackScreenProps<PostsParamsList, "postsHome">;

export const PostsHomeScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const { followedSites, isFetching: isUserFetching } = useUser();

  const {
    posts,
    setPost,
    getPosts,
    refreshPosts,
    isRefreshing: isPostRefreshing,
    isFetching: isPostsFetching,
  } = usePosts();
  const { setSite } = useSites();

  useFocusEffect(
    useCallback(() => {
      getPosts();
    }, [getPosts])
  );

  const onCardPressed = (post: Post, firstFaq?: boolean) => {
    setPost(post);
    navigation.navigate("postDetails", {
      postId: post.id,
      fromSite: false,
      firstFaq: firstFaq ? true : false,
    });
  };

  const onSitePressed = (post: Post) => {
    setSite(post.site);
    navigation.navigate("siteDetails", {
      siteId: Number(post.site?.id ?? post.site ?? 0),
    });
  };

  //<NoLoggedInForPosts navigation={navigation} />

  return (
    <BackgroundLayout style={styles.container}>
      <SView style={{ flex: 1 }}>
        {/*Platform.OS === "web" && (
          <TouchableOpacity
            style={styles.download}
            onPress={() => {
              // TODO lien vers le store
              // eslint-disable-next-line no-alert
              alert("Lien vers le store");
            }}
          >
            <LinearGradient
              colors={[theme.colors.gradientStart, theme.colors.gradientEnd]}
              start={{ x: 0, y: 0.5 }}
              end={{ x: 1, y: 1 }}
              style={styles.gradientContainer}
            />
            <View style={styles.downloadContent}>
              <Icon
                name="mobile"
                type="FontAwesome"
                sizeName="larger"
                colorName="gradientText"
              />
              <JText
                label={"Continuer sur l'application"}
                fontWeight={"500"}
                style={styles.downloadText}
                colorName="gradientText"
              />
            </View>
          </TouchableOpacity>
          )*/}
        {isUserFetching || isPostsFetching || !followedSites ? (
          <BoxedLayout>
            <LoadingScreen />
          </BoxedLayout>
        ) : (
          <>
            {Boolean(followedSites?.length === 0) &&
              Boolean(!posts || posts?.length === 0) && (
                <BoxedLayout>
                  <NoSitesFollowed navigation={navigation} />
                </BoxedLayout>
              )}

            {Boolean(followedSites?.length > 0) &&
              Boolean(!posts || posts?.length === 0) && (
                <NoPosts navigation={navigation} />
              )}
            {Boolean(posts?.length) && (
              <PostsList
                data={posts}
                onPress={onCardPressed}
                onSitePressed={onSitePressed}
                refreshing={isPostRefreshing}
                onRefresh={refreshPosts}
                contentContainerStyle={{ marginTop: theme.normalize(30) }}
              />
            )}
          </>
        )}
      </SView>
    </BackgroundLayout>
  );
};
