import React from "react";
import { Clickable, JImage, JText } from "../jlib";
import { View } from "react-native";
import { ThreadType } from "../../types/ThreadType";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ThreadTypeItemStyle";

type Props = {
  type: ThreadType;
  onPressed: (type: ThreadType) => void;
};

export const ThreadTypeItem: React.FC<Props> = ({ type, onPressed }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme, type);

  return (
    <Clickable
      onPress={() => {
        onPressed(type);
      }}
      style={styles.container}
    >
      <View style={styles.contentContainer}>
        <View style={styles.card}>
          {type.imageLightMode ? (
            <JImage
              source={type.imageLightMode.url}
              type={"Uri"}
              style={styles.iconImage}
            />
          ) : (
            <></>
          )}
        </View>
        <JText
          label={type.name}
          isBold
          sizeName={"mediumLarge"}
          style={styles.label}
        />
      </View>
    </Clickable>
  );
};
