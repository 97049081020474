import React, { useState } from "react";
import { Post } from "../../types/Post";
import {
  Card,
  Clickable,
  Column,
  Divider,
  Icon,
  JImage,
  JText,
  Tile,
} from "../jlib";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { PostItemHeader } from "./PostItemHeader";
import fnStyles from "./PostItemStyle";
import dayjs from "dayjs";
import { usePosts } from "./../../hooks/usePosts";
import { useUser } from "hooks";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isHorizontalMode } from "components/utils/deviceMode";
import { useTranslation } from "react-i18next";
import { JHtml } from "components/jlib/jHtml/JHtml";

type Props = {
  post: Post;
  onPress: (post: Post, firstFaq?: boolean) => void;
  onSitePressed?: (post: Post) => void;
};

export const PostItem: React.FC<Props> = ({ post, onPress, onSitePressed }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const styles = fnStyles(theme, width, isHorizontalMode({ width, height }));
  const { onUpdateUpVoted } = usePosts();
  const { localHistoryThanksPosts } = useUser();

  const [isLikeUpdating, setIsLikeUpdating] = useState(false);

  const onLike = async () => {
    setIsLikeUpdating(true);
    await onUpdateUpVoted(post);
    setIsLikeUpdating(false);
  };

  const hasLiked = Boolean(
    localHistoryThanksPosts?.find(
      (postThanksId: any) => postThanksId === post?.id
    )
  );

  return (
    <BoxedLayout>
      <View style={styles.container}>
        <Card style={styles.card}>
          <Clickable onPress={() => onPress(post)}>
            <PostItemHeader post={post} />
            {post?.mainImage && (
              <JImage
                source={post.mainImage.url}
                type={"Uri"}
                style={styles.mainImage}
                alt={t("titles.illustrationOf", { title: post.title })}
              />
            )}
          </Clickable>
          <View style={styles.content}>
            <Column>
              <Clickable onPress={() => onPress(post)}>
                {post.contentRich ? (
                  <JHtml
                    source={post.contentRich}
                    truncateLength={250}
                    truncateKeepBr
                    //    contentWidth={width}
                  />
                ) : (
                  <JText
                    label={post.content}
                    sizeName="mediumLarge"
                    numberOfLines={4}
                  />
                )}

                <JText
                  labelKey="post.readMore"
                  style={styles.more}
                  isBold
                  sizeName={"mediumLarge"}
                />
              </Clickable>
              <Divider />
              <View style={styles.actions}>
                {post.publishedDate ? (
                  <View style={styles.time}>
                    <Icon
                      name={"clock"}
                      type={"MaterialCommunity"}
                      colorName={"info"}
                    />
                    <JText
                      label={dayjs(post.publishedDate).locale("fr").fromNow()}
                      fontWeight={"300"}
                      sizeName={"mediumLarge"}
                      isItalic
                      style={styles.timeLabel}
                    />
                  </View>
                ) : (
                  <View />
                )}

                <View style={styles.actionsContainer}>
                  {(post.thanks || 0) > 0 && (
                    <JText
                      label={`${post.thanks}`}
                      fontWeight={"300"}
                      sizeName={"mediumLarge"}
                      style={styles.thanksCount}
                    />
                  )}

                  {isLikeUpdating ? (
                    <ActivityIndicator style={styles.likeSpinner} />
                  ) : (
                    <Clickable
                      onPress={onLike}
                      style={hasLiked ? styles.downThanks : styles.upThanks}
                    >
                      <Icon
                        name={"like2"}
                        type={"AntDesign"}
                        colorName={hasLiked ? "infoLight" : "infoMedium"}
                      />
                    </Clickable>
                  )}

                  {post.qAndAs.length > 0 && (
                    <Tile
                      iconType={"MaterialCommunity"}
                      iconName={"message-reply-outline"}
                      iconSizeName={"mediumLarge"}
                      color="primary"
                      iconColorName="primaryDarker"
                      label={`${post.qAndAs.length || 0}`}
                      labelStyle={styles.tile}
                      onPress={() => onPress(post, true)}
                    />
                  )}
                </View>
              </View>
            </Column>
          </View>
        </Card>
      </View>
    </BoxedLayout>
  );
};
